import React from 'react'
import SVG from './SVG'

const Relab = (props) => {
  return (
    <SVG viewBox="0 0 30 14" {...props}>
      <path d="M7.29785 13.0278L4.66577 7.40767C4.65441 7.3698 4.6279 7.35087 4.59003 7.35087H1.88222C1.83298 7.35087 1.80647 7.37737 1.80647 7.42661V12.971C1.80647 13.096 1.74209 13.1604 1.61711 13.1604H0.189358C0.0643816 13.1604 0 13.096 0 12.971V0.189358C0 0.0643816 0.0643816 0 0.189358 0H5.24521C5.99506 0 6.65782 0.155272 7.22968 0.469606C7.79775 0.78394 8.24085 1.22325 8.55518 1.78375C8.86951 2.34803 9.02479 2.99943 9.02479 3.73792C9.02479 4.61654 8.79756 5.35882 8.33931 5.96477C7.88107 6.5745 7.24482 6.98351 6.43059 7.19559C6.38135 7.2221 6.36621 7.2524 6.39272 7.29027L9.11947 12.9293C9.13083 12.9559 9.1384 12.9861 9.1384 13.024C9.1384 13.1111 9.08917 13.1566 8.98692 13.1566H7.50235C7.4001 13.1566 7.33193 13.1111 7.29406 13.024L7.29785 13.0278ZM1.81026 1.63984V5.85115C1.81026 5.90038 1.83677 5.9269 1.886 5.9269H5.02555C5.6883 5.9269 6.22229 5.72996 6.62373 5.3361C7.02517 4.94224 7.22589 4.41961 7.22589 3.76822C7.22589 3.11683 7.02517 2.57148 6.62373 2.17004C6.22229 1.7686 5.6883 1.56788 5.02555 1.56788H1.886C1.83677 1.56788 1.81026 1.59439 1.81026 1.64363V1.63984Z" fill="white"/>
      <path d="M20.0758 1.56372H13.4028C13.3536 1.56372 13.3271 1.59023 13.3271 1.63946V5.68036C13.3271 5.72959 13.3536 5.7561 13.4028 5.7561H19.0532C19.1782 5.7561 19.2426 5.82048 19.2426 5.94545V7.13084C19.2426 7.25581 19.1782 7.32019 19.0532 7.32019H13.4028C13.3536 7.32019 13.3271 7.3467 13.3271 7.39594V11.5315C13.3271 11.5807 13.3536 11.6073 13.4028 11.6073H20.0758C20.2007 11.6073 20.2651 11.6716 20.2651 11.7966V12.982C20.2651 13.107 20.2007 13.1713 20.0758 13.1713H11.7137C11.5887 13.1713 11.5244 13.107 11.5244 12.982V0.200344C11.5244 0.0753679 11.5887 0.0109863 11.7137 0.0109863H20.0758C20.2007 0.0109863 20.2651 0.0753679 20.2651 0.200344V1.38572C20.2651 1.5107 20.2007 1.57508 20.0758 1.57508V1.56372Z" fill="white"/>
      <path d="M26.3852 7.96021C24.9422 7.96021 23.7758 9.13044 23.7758 10.5696C23.7758 12.0087 24.946 13.1789 26.3852 13.1789C27.8243 13.1789 28.9945 12.0087 28.9945 10.5696C28.9945 9.13044 27.8243 7.96021 26.3852 7.96021ZM26.3852 12.0958C25.5444 12.0958 24.8589 11.4103 24.8589 10.5696C24.8589 9.72881 25.5444 9.04333 26.3852 9.04333C27.2259 9.04333 27.9114 9.72881 27.9114 10.5696C27.9114 11.4103 27.2259 12.0958 26.3852 12.0958Z" fill="white"/>
      <path d="M26.5719 5.57673C28.0094 5.47445 29.0918 4.22624 28.9895 2.78877C28.8872 1.3513 27.639 0.268915 26.2015 0.371198C24.7641 0.47348 23.6817 1.7217 23.784 3.15916C23.8862 4.59663 25.1345 5.67902 26.5719 5.57673Z" fill="white"/>
    </SVG>
  )
}

export default Relab
