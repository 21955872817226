export const TITLE_EMPTY = '還未設定互動模組名稱';

export const EDITOR_QUESTION_TITLE = {
  classic: '測驗題目',
  measure: '測驗題目',
  swiper: '挑戰題目',
  runway: '障礙物題目',
  catcher: '挑戰題目',
  survey: '問卷題目',
  multifactor: '測驗題目',
  canvas: '搭配組合'
};

export const EDITOR_RESULT_TITLE = {
  classic: '個人化結果總覽',
  measure: '個人化結果總覽',
  swiper: '個人化結果總覽',
  runway: '個人化結果總覽',
  catcher: '個人化結果總覽',
  survey: '個人化結果',
  multifactor: '個人化結果',
  canvas: '客製結果'
};

export const ERROR = '發生錯誤，請稍候。';
export const LOADING = '讀取中';

export const pallette = [
  ['#ffcdd2', '#ffe0b2', '#ffecb3', '#b2dfdb', '#bbdefb'],
  ['#f89a9a', '#ffb74d', '#ffd54f', '#4db6ac', '#64b5f6'],
  ['#f44336', '#ff9800', '#ffc107', '#009688', '#2196f3'],
  ['#d32f2f', '#f57c00', '#ffa000', '#00796b', '#1976d2'],
  ['#000000', '#595959', '#999999', '#d9d9d9', '#ffffff']
];
export const backgroundProportionProps = {
  proportional: {
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat'
  },
  auto: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  }
};

export const IMAGE_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN89eLZfwAJNQO5LkvE4AAAAABJRU5ErkJggg==';

export const survey = {
  quota: {
    TITLE_COUNT: '問卷填答數',
    TITLE_MAX: '最大填答數',
    MAX: 1000,
    DESCRIPTION:
      '免費填答額度使用完畢後，模組將會改為非發布狀態；若是需要加購填答數量，請聯絡客服。'
  }
};

export const canvas = {
  PRIMARY: '#000000',
  BACKGROUND: '#FFFFFF',
  CHOICE_ACTIVE: '#000000',
  CHOICE_BACKGROUND: '#EAE8E6',
  OPTION_BACKGROUND: '#ffffff'
};

export const CHART_EMPTY = '還未有資料';
export const CHART_FILLS = ['#FFC23C', '#52D5AE', '#000000'];

export const TEMPLATE_KEY = '_template';
