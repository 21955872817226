import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Text, IconButton, useConst, Box, Flex, Stack, Icon, HStack } from '@chakra-ui/react';
import { BsInstagram } from 'react-icons/bs';
import Relab from 'components/Relab';
import IconOutlineMail from '../icons/OutlineMail';

import Link from 'components/Link';
import Logo from 'components/Logo';
import { Media, responsive } from '../../contexts/responsive';

// const iconSize = {
//   base: '2.5em',
//   md: '3em',
//   '2xl': '4em'
// };

let dom

const links = [
  { Iconic: Relab, href: 'https://relab.cc', key: 'relab' },
  { Iconic: IconOutlineMail, href: 'mailto:ooopenlab@relab.cc', key: 'contact' },
  { Iconic: BsInstagram, href: 'https://www.instagram.com/ooopenlab/', key: 'instagram' },
]

const Footer = ({ pageProps, fullpageApi, ...props }) => {
  const router = useRouter();
  const year = useConst(() => new Date().getFullYear());
  useEffect(() => {
    if (document) {
      dom = document.getElementById("cover")
    }
  } ,[])
  return (
    <Box
      as="footer"
      pos="relative"
      zIndex="10"
      pt={responsive('1.125rem', '1.75rem', '2.75rem')}
      px={responsive('1.125em', '2em')}
      pb={responsive('1.125rem', '1rem', '2rem')}
      color="white"
      bg="black"
      {...props}
    >
      <Flex alignItems="center" justifyContent={'space-between'}>
        <Flex flexDir={responsive('column', 'row')}>
          <Box
            cursor={'pointer'}
            onClick={() => {
              if (dom) {
                dom.scrollIntoView({ behavior: 'smooth' }, true)
              }
            }}
            as={!dom && Link}
            href={dom ? undefined : '/'}
            fontSize="0"
            lineHeight="1"
          >
            <Logo variant="full" width={responsive('89%', '200px')} />
          </Box>
          <Text
            ms={responsive(0, 6)}
            pb={responsive(0, 5)}
            mt={responsive('1em', 0)}
            fontSize={responsive('0.875rem', 'xl')}
            letterSpacing={responsive('7.2px', 0)}
            fontWeight="bold"
          >
            讓溝通變好玩
          </Text>
        </Flex>
        <Stack alignItems="flex-end">
          <Stack
            direction={responsive('column-reverse', 'row')}
            spacing={responsive(2, 4)}
            alignItems={responsive('flex-end', 'center')}
          >
            <Stack
              fontSize={responsive('0.875rem', '1em')}
              direction={responsive('column', 'row')}
              spacing={responsive(2, 4)}
              alignItems={responsive('flex-end', 'center')}
            >
              {router.pathname !== '/' && (
                <Link
                  href="/"
                >
                  回首頁
                </Link>
              )}
              <Link href="/terms" target="_blank">
                條款與隱私權
              </Link>
            </Stack>
            <HStack spacing={responsive(2, 4)}>
              {links.map(({ Iconic, href, key }) => (
                <IconButton
                  as={Link}
                  href={href}
                  icon={<Icon as={Iconic} w={[5, 6]} h={[5, 6]} />}
                  isRound
                  width={responsive('2.5em', '3em')}
                  height={responsive('2.5em', '3em')}
                  // fontSize={iconSize}
                  target="_blank"
                  border="1px solid white"
                  aria-label={key}
                  key={key}
                />
              ))}
            </HStack>
          </Stack>
          <Media greaterThanOrEqual="md">
            <Text>
              Copyright © {year}&nbsp;
              <Link color="inherit" href="/">
                OOOPEN Lab
              </Link>
            </Text>
          </Media>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Footer;
