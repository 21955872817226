import React, { useContext } from 'react';
import { Box, Center, Flex, Heading, VStack } from '@chakra-ui/react';
import SVG from 'components/SVG';
import Button from 'components/Button';
import { Media, responsive } from '../contexts/responsive';

import {
  CUSTOMIZATION,
  TUTORIAL,
  FEATURE_REQUEST,
  MODULE_REQUEST
} from 'contexts/contact/needs';
import contactContext from 'contexts/contact/context';

export const content = [
  {
    image: 'creativeHelp',
    viewbox: '0 0 236.41 184.17',
    text: '如果你發現需要真人協助請大膽說出來！',
    button: [
      { text: '我…需要教學', form: TUTORIAL.label, trackerName: `contact_${TUTORIAL.name}` },
      { text: '我有特殊需求', form: CUSTOMIZATION.label, trackerName: `contact_${TUTORIAL.name}` }
    ]
  },
  {
    image: 'creativeWish',
    viewbox: '0 0 234.36 197.21',
    text: '如果你有新的創意或願望拜託一定要讓我們知道！',
    button: [
      { text: '我想要許願新的功能', form: FEATURE_REQUEST.label, trackerName: `contact_${FEATURE_REQUEST.name}` },
      { text: '我想要許願新的遊戲化模組', form: MODULE_REQUEST.label, trackerName: `contact_${MODULE_REQUEST.name}` }
    ]
  }
];

const Arrow = ({ text, ...props }) => (
  <Box
    sx={{ '.button:hover &': { opacity: 1 } }}
    pos="absolute"
    opacity={0}
    left="100%"
    top="50%"
    transform={'translate(0.75vw, -50%)'}
    width={['2em', text.length > 6 ? '2.5vw' : '4.5vw']}
    {...props}
  >
    <SVG viewBox="0 0 91.93 26.95" alt="">
      <use xlinkHref="/sprites/home.svg#longArrow" />
    </SVG>
  </Box>
);

export const Module = ({ d, ...props }) => {
  const { openForm } = useContext(contactContext);
  return (
    <Box
      width={responsive('100%', '50%')}
      maxW={responsive('30em', 'unset')}
      px={responsive('0.625em', '9.5%')}
      mx="auto"
      {...props}
    >
      <Center flexDirection="column">
        <Box flex="0 0 7rem">
          <SVG viewBox={d.viewbox} w={120} alt="">
            <use xlinkHref={`/sprites/home.svg#${d.image}`} />
          </SVG>
        </Box>
        <Heading
          textAlign="center"
          mt={responsive('2rem', '3.75rem')}
          fontSize={responsive('1.25em', '1.7vw')}
        >
          {d.text}
        </Heading>
        <VStack
          width="100%"
          pt={responsive('1em', '2.5em')}
          spacing={responsive(3, 4)}
        >
          {d.button.map(({ text, form, trackerName }, k) => (
            <Box width="100%" key={k}>
              <Button
                className="button"
                variant="outline"
                width="100%"
                height="auto"
                fontWeight="bold"
                py={responsive('1.125em', '1.6vw')}
                fontSize={responsive('1em', '1.5vw')}
                _hover={{
                  bg: 'black',
                  color: 'white',
                  textDecoration: 'none'
                }}
                border="1px solid"
                borderColor="black"
                onClick={() => openForm(form)}
                trackerName={trackerName}
                trackerType="contact_click"
              >
                <Box lineHeight={1} pos="relative">
                  {text}
                  <Arrow text={text} />
                </Box>
              </Button>
            </Box>
          ))}
        </VStack>
      </Center>
    </Box>
  );
};

const Contact = () => {
  return (
    <Box pt={responsive('2.25em', '6.25em')} pb={responsive('3em', '5em')}>
      <Flex
        pos="relative"
        flexDir={responsive('column', 'row')}
        mx={['1.5em', '6.25%']}
      >
        {content.map((d, i) => (
          <Module pt={responsive(i ? '3.375em' : '1.125em', 0)} pb={!i && responsive('2.5em', 0)} d={d} key={i} />
        ))}
        <Box
          as={Media}
          greaterThanOrEqual="md"
          pos="absolute"
          top="0"
          bottom="0"
          width="1px"
          bg="black"
          left="50%"
          transform="translateX(-50%)"
        />
        <Box
          as={Media}
          lessThan="md"
          pos="absolute"
          top="50%"
          bottom="0"
          height="1px"
          bg="black"
          width={'100%'}
          transform="translateY(50%)"
        />
      </Flex>
    </Box>
  );
};

export default Contact;
