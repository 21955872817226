import React, { useState } from 'react';
import { IMAGE_PLACEHOLDER } from 'constants/quiz';
import { AspectRatio, Box, forwardRef, Icon, Skeleton } from '@chakra-ui/react';
import Image from 'next/image';
import { RiImageFill } from 'react-icons/ri';
import imgproxy from 'imgproxy';

const RatioImage = forwardRef(
  (
    {
      src,
      ratio = 1,
      crop,
      alt,
      onLoadingComplete,
      isDisabled,
      objectFit,
      skeleton,
      quality = 90,
      sizes,
      priority,
      ...props
    },
    ref
  ) => {
    const isValidSrc = src !== 'undefined';
    const [loaded, setLoded] = useState(!skeleton || priority);
    const handleLoadComplete = (e) => {
      if (typeof onLoadingComplete === 'function') onLoadingComplete(e);
      setLoded(true);
    };
    const placeholder = (
      <AspectRatio ratio={ratio} opacity={isDisabled ? 0.4 : 1} {...props}>
        <Box as="span" color="gray.500" bg="gray.100">
          <Icon as={RiImageFill} w={5} h={5} />
        </Box>
      </AspectRatio>
    );
    if (isDisabled) return placeholder;
    if (!isValidSrc) return null;
    const ratioImage = (
      <AspectRatio ratio={ratio} ref={ref} {...props}>
        <Image
          src={src}
          fill
          alt={alt}
          onLoadingComplete={handleLoadComplete}
          quality={quality}
          sizes={sizes}
          placeholder="blur"
          blurDataURL={IMAGE_PLACEHOLDER}
          loader={process.env.NODE_ENV === 'production' ? imgproxy : undefined}
          style={{ objectFit }}
        />
      </AspectRatio>
    );
    return src ? (
      <Skeleton isLoaded={loaded}>{ratioImage}</Skeleton>
    ) : (
      placeholder
    );
  }
);

RatioImage.defaultProps = {
  overflow: 'hidden',
  transform: 'translateZ(0)',
  objectFit: 'contain'
};

export default RatioImage;
